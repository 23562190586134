/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";

import OwlCarousel from 'react-owl-carousel2';
import "../../common/css/owl.carousel.css";
import HomeBanner from "../Home/HomeSubCompo/HomeBanner";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import {
  apiUrl,
  tagImageUrl,
  appId,
  cateringId,
  deliveryId,
} from "../Helpers/Config";
import { showLoader, hideLoader } from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
  GET_ZONE_DETAIL,
  GET_PROMOTIONLIST 
} from "../../actions";
import gridimg from "../../common/images/grid.png";
import filterImg from "../../common/images/filter.svg";
import searchimg from "../../common/images/search.svg";
import closebl from "../../common/images/close-block.png";
import banner from "../../common/images/inner-banner.jpg";
import Megaphoneicon from "../../common/images/megaphone.png";
import MegaphoneBlueicon from "../../common/images/megaphone-blue.png";
import Slider from "react-slick";

const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedSlugType: "",
      searchSubCat: "",
      searchProVal: "",
      viewProductSlug: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
      productload: "Yes",
      promotions: [],
    };
    this.props.getPromotionList();
    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    if (avilablityIdTxt === cateringId) {
      this.props.history.push("/");
      return;
    }

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });
      this.props.history.push("/");
      return;
    }

    this.props.getSettings();
    var availbty = cookie.load("defaultAvilablityId");
    var outltIdTxt =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
      this.state["delivery_outlet_id"] = outltIdTxt;
      this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
    }
    this.props.getMenuNavigationList();
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.promotionlist !== this.props.promotionlist) {
      if (nextProps.promotionlist[0].status === "ok") {
        this.setState({ status: nextProps.promotionlist[0].status });
        this.setState({
          promotions: nextProps.promotionlist[0].result_set.my_promo,
        });    
      }
    }

    let slugType =
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";
    let slugValue =
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    let proValue =
      typeof this.props.match.params.proValue !== "undefined"
        ? this.props.match.params.proValue
        : "";
    let selectedNavigation = nextProps.selectedNavigation;
    let selectedSlugType = nextProps.selectedSlugType;
    var searchSubCat = "";
    if (!isEqual(this.props.match.params, nextProps.match.params)) {
      slugType =
        typeof nextProps.match.params.slugType !== "undefined"
          ? nextProps.match.params.slugType
          : "";
      slugValue =
        typeof nextProps.match.params.slugValue !== "undefined"
          ? nextProps.match.params.slugValue
          : "";
      proValue =
        typeof nextProps.match.params.proValue !== "undefined"
          ? nextProps.match.params.proValue
          : "";
    }

    if (slugValue !== "") {
      if (slugType !== "category" && slugType !== "subcategory") {
        searchSubCat = slugValue;
        slugValue = slugType;
        slugType = "category";
        /*$('.search_result').hide();*/
        var tmpVl = "";
        $("#productsearch").val(tmpVl);
        $("#clearSearch").hide();
        $(".hsearch_sec").removeClass("open");
        $(".hsearch_trigger").removeClass("active");
        setTimeout(function () {
          $(window).scrollTo($("." + proValue), 100);
        }, 2000);
      }
      selectedNavigation = slugValue;
    }

    if (slugType === "") {
      slugType = selectedSlugType;
    }

    if (
      selectedNavigation !== this.state.selectedNavigation ||
      this.state.selectedCategoryName !== nextProps.selectedCatry
    ) {
      showLoader("productlist-main-div", "class");

      if (proValue === "") {
        setTimeout(function () {
          $(window).scrollTo($(".common-inner-banner"), 800);
        }, 3000);
      }

      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].category_name
            : nextProps.selectedCatry;
      }

      this.setState({
        selectedNavigation: selectedNavigation,
        navigateMenu: nextProps.navigateMenu,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        selectedCategoryName: categoryNameTxt,
        searchSubCat: searchSubCat,
        searchProVal: proValue,
      });
    }
  }

  componentDidMount() {

    var s = $(".product-menu-full");
    var pos = s.position();            
    $(window).scroll(function() {
      var windowpos = $(window).scrollTop();
      if (windowpos >= pos.top & windowpos >=900) {
        s.addClass("full-bg");
      } else {
        s.removeClass("full-bg"); 
      }
    });

    /*SCROLL FIXED PRODUCT PAGE*/
    $(document).click(function (e) {
      if (!$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")) {
        if ($(".product_search_result").is(":visible")) {
          $(".product_search_result").hide();
          $("#searchKeyWord").val("");
        }
      }
    });

    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();

        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });
    $(".product_search_result").hide();
  }

  sateValChange = (field, value) => {
        if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value }, function () {
      });
    } else if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    } else if (field === "productlist" && value !== "") {
      this.setState({
        searchProResult: value,
        selectedProResult: value,
      });
    } else if (field === "setFiltetTag" && value !== "") {
      this.setState({
        setFiltetTag: value,
      });
    } else if (field === "openOutletTime" && value !== "") {
      this.setState(
        {
          openOutletTime: value,
        },
        function () {
        }
      );
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    /*$("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });*/
  }

  searchProKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.searchProResult.filter(function (item) {
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return value;
        }
      });

    $("#product_search_result").show();
    if (value === "") {
      $("#product_search_result").hide();
    }
    this.setState({ selectedProResult: matches });
  };

  /* load product search result  */
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            to={
              "/products/" +
              loaddata.cate_slug +
              "/" +
              loaddata.subcate_slug +
              "/" +
              loaddata.value
            }
            title="Product Details"
          >
            {loaddata.label}
          </Link>
        </li>
      ));
    } else {
      return <li className="no-pro-found">No Product found</li>;
    }
  }

  loadTags() {
    axios
      .get(apiUrl + "/products/product_tags?app_id=" + appId + "&status=A")
      .then((res) => {
        /* Success response */
        if (res.data.status === "ok") {
          this.displayTag(res.data.result_set);
        } else {
        }
      });
  }

  displayTag(tags) {
    let tagList = "";
    if (tags !== "") {
      var current = this;
      tagList = tags.map(function (item, index) {
        return (
          <li className="custom_checkbox" key={index}>
            <input
              type="checkbox"
              className="restaurants_tag"
              value={item.pro_tag_id}
              onChange={(e) => current.handleChange(current, e)}
            />
            <span>
              <img
                src={tagImageUrl + "" + item.pro_tag_image}
                alt={item.pro_tag_name}
              />
              {item.pro_tag_name}
            </span>
          </li>
        );
      });
    }
    this.setState({ tagList: tagList });
 }

  handleChange(section, event) {
    let filterTag = section.state.filterTag;
    if (event.target.checked === true) {
      filterTag.push(event.target.value);
    } else {
      var index = filterTag.indexOf(event.target.value);
      filterTag.splice(index, 1);
    }
    section.setState({ filterTag: filterTag, setFiltetTag: "Yes" });
  }
  clearFilter(event) {
    event.preventDefault();
    $(".restaurants_tag").prop("checked", false);
    this.setState({ filterTag: [], setFiltetTag: "Yes" });
  }

    __promotionListing() {
    var promotionsArr =
      this.state.promotions !== undefined ? this.state.promotions : Array();
    if (Object.keys(promotionsArr).length > 0) {
      return this.state.promotions.map((promo, index) => (

        

                <div className="innerproduct-item notify-box" key={index} >
        <div className="left">
        <div className="notify-icon offer_img">
          <img src={Megaphoneicon} className="no_mou" />
          <img src={MegaphoneBlueicon} className="mou_active" />
        </div>  
        </div>
        <div className="right">
        <div className="notify-cnt">
          <h3>{promo.promotion_title}</h3>
          {promo.promo_desc!='' && (
          <p>{Parser(promo.promo_desc)}</p>
          )}
        </div> 
        </div> 
        <span className="offer_circle"></span>
      </div>


 
      ));
    } 
  }


  render() {

    var offerSlider = {
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: true,
      infinite: false,
      responsive: [
      {
      breakpoint: 1024,
      settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      },
      },
      {
      breakpoint: 767,
      settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      },
      },
      {
      breakpoint: 600,
      settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      },
      },
      {
      breakpoint: 480,
      settings: { 
      slidesToShow: 1,
      slidesToScroll: 1,
      },
      },
      ],
      };

    return (
      <div className="productpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
        />
        {/* Header End */}
 <div className="syed-container">
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Our Menu</p>
          </div>
        </div>

        <section className="product-menu-listing">
          <div className="product-menu-full">
            <MenuNavigation
              {...this.props}
              productState={this.state}
              sateValChange={this.sateValChange}
            />
          </div>
          {Object.keys(this.state.promotions).length > 0 &&
          <div className="detail-pg">
                <div className="detail-pg-box">
                  <div className="detail-pg-slider">
                    <div className="detail-pg-container">
                       <Slider {...offerSlider}>
                            {this.__promotionListing()}
                       </Slider>
                    </div>
                  </div>
                </div>
          </div>
          }
       
          <div className="container">
            <div className="product-lhs">
              <ProductList
                {...this.props}
                productState={this.state}
                sateValChange={this.sateValChange}
              />
            </div>
         </div>
        </section>
</div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    zonedetails: zonedetailArr,
    promotionlist: state.promotionlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getMenuNavigationList: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
    getPromotionList: (customerParam) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Products);
